import { Modal, Button, Form, Container, ButtonGroup } from "react-bootstrap"
import { OutputSetup } from "../../models/Models"
import { AuthContext } from "../../models/AuthContextProvider";
import { Component, useContext, useEffect, useRef, useState } from "react";
import React from "react";
import ReactMarkdown, { Components } from 'react-markdown';
import remarkGfm from 'remark-gfm';
import * as Icon from 'react-bootstrap-icons';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { CopyToClipboard } from "react-copy-to-clipboard";

interface Message {
    role: "user" | "model";
    content: string;
}

export function OutputAiChat(props: {
    editor: OutputSetup
    setEditor: React.Dispatch<React.SetStateAction<OutputSetup | undefined>>
    reload: () => void
}) {
    let context = useContext(AuthContext);
    const [textareaValue, setTextareaValue] = useState('');
    const [chatBottom, setChatBottom] = useState(0)
    const [history, setHistory] = useState<Message[]>([]);
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const update = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (textareaValue.trim() !== "") {
            setHistory([...history, { role: "user", content: textareaValue }]);
            setTextareaValue("");
            // Здесь можно добавить логику для отправки запроса к AI модели
            // и добавления ответа в history с role: "assistant"
            // ...
        }
    }
    const deleteMessage = (index: number) => {
        const newHistory = [...history];
        newHistory.splice(index, 1);
        setHistory(newHistory);
    };
    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "0px";
            var maxHeight = textareaRef.current.scrollHeight
            if (maxHeight > window.innerHeight / 2) maxHeight = window.innerHeight / 2
            textareaRef.current.style.height = maxHeight + "px";
            setChatBottom(maxHeight)
        }
    }, [textareaValue, history]);

    useEffect(() => {
        // Отправляем историю на бекенд при изменении
        const sendHistory = async () => {
            if (history.length > 0)
                try {
                    const messagesToSend = history.map((msg, index) => ({
                        ObjectId: props.editor.Id,
                        Index: index,
                        Role: msg.role,
                        Content: msg.content
                    }));

                    const response = await fetch(`/Integrations/SaveAiMessages/${props.editor.Id}`, { // Замените на свой URL
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(messagesToSend)
                    });

                    if (!response.ok) {
                        throw new Error(`Ошибка отправки истории: ${response.status}`);
                    }
                } catch (error) {
                    console.error('Ошибка при отправке истории:', error);
                }
        };

        sendHistory();
    }, [history])

    useEffect(() => {
        // Загрузка сообщений при монтировании компонента и изменении editor.Id
        const loadMessages = async () => {
            if (!props.editor.Id) return
            context.setLoading(true);
            try {
                const response = await fetch(`/Integrations/LoadAiMessages/${props.editor.Id}`);
                if (!response.ok) {
                    throw new Error(`Ошибка загрузки сообщений: ${response.status}`);
                }
                const loadedMessages: Message[] = await response.json();
                setHistory(loadedMessages);
            } catch (error) {
                console.error('Ошибка при загрузке истории:', error);
            } finally {
                context.setLoading(false);
            }
        };

        loadMessages();
    }, [props.editor.Id]);

    const generateAiResponse = async () => {
        try {
            context.setLoading(true);
            const response = await fetch('/Integrations/GenerateAi', { // Замените на свой URL
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(history)
            });

            if (!response.ok) {
                throw new Error(`Ошибка генерации ответа: ${response.status}`);
            }

            const data = await response.json();
            console.log('Ответ', data);
            if (data.error)
                context.showMessage(data.error, true, 'lg');
            else {
                // Предполагаем, что сервер возвращает объект { content: "ответ нейросети" }
                const newMessage: Message = { role: "model", content: data.text };
                setHistory([...history, newMessage]);
            }
        } catch (error) {
            console.error('Ошибка при генерации ответа AI:', error);
            // ... (обработка ошибки)
        } finally {
            context.setLoading(false);
        }
    };
    // Компонент для подсветки кода
    const CodeBlock = {
        value: (props: { children: string; className: string }) => {
            const language = props.className?.replace(/language-/, '') || 'python';
            return (
                <SyntaxHighlighter style={darcula} language={language}>
                    {props.children}
                </SyntaxHighlighter>
            );
        }
    } as Partial<Components>

    return (
        <Modal show={props.editor != undefined} onHide={() => props.setEditor(undefined)} centered size="xl" >
            <Modal.Header closeButton className="mt-2">
                <div>
                    <h1 className="mt-3">{props.editor.Name}
                        <span className="badge bg-secondary ms-2">{props.editor.Tag}</span>
                        <span className="badge bg-secondary ms-2">{props.editor.Id}</span>
                    </h1>
                    <h2 className="mb-4">Помощь генеративной модели</h2>
                </div>
            </Modal.Header>
            <Modal.Body className="overflow-y-auto" style={{ paddingBottom: `${chatBottom + 30}px` }}>
                <div className="flex-grow-1 d-flex flex-column gap-3">
                    {history.map((msg, index) => (
                        <div key={index} className={`mb-2`}>
                            <div className={`ai-chat position-relative p-2 rounded ${msg.role !== "user" ? "bg-light" : "bg-white border"}`}>
                                <ReactMarkdown
                                    components={{
                                        code(props) {
                                            const { children, className, node, ...rest } = props
                                            const match = /language-(\w+)/.exec(className || '')
                                            const code = String(children).replace(/\n$/, '')
                                            return match ? (<>
                                                <SyntaxHighlighter
                                                    {...rest as any}
                                                    PreTag="div"
                                                    children={code}
                                                    language={match[1]}
                                                />
                                                <button className="btn btn-secondary btn-sm">
                                                    <CopyToClipboard text={code} onCopy={e => console.log(e)}>
                                                        <Icon.Clipboard2Check />
                                                    </CopyToClipboard>
                                                </button>
                                            </>
                                            ) : (
                                                <code {...rest} className={className}>
                                                    {children}
                                                </code>
                                            )
                                        }
                                    }} remarkPlugins={[remarkGfm]}>{msg.content}</ReactMarkdown>
                                <div className="position-absolute top-0 end-0 context-buttons">
                                    <ButtonGroup size="sm">
                                        <Button variant="">
                                            <CopyToClipboard text={msg.content}>
                                                <Icon.Clipboard2Check />
                                            </CopyToClipboard>
                                        </Button>
                                        <Button variant="" onClick={() => deleteMessage(index)}>
                                            <Icon.Trash />
                                        </Button>
                                    </ButtonGroup>
                                </div>

                            </div>
                        </div>
                    ))}
                </div>
                <Form onSubmit={update} className="position-fixed bottom-0 start-0 p-3 w-100 pe-4">
                    <div className="d-flex w-100 gap-2 align-items-end pe-2">
                        <Form.Control
                            as="textarea"
                            rows={2}
                            value={textareaValue}
                            onChange={(e) => setTextareaValue(e.target.value)}
                            placeholder="Введите что-нибудь..."
                            ref={textareaRef}
                        />
                        <Button variant="primary" type="submit" size="lg">Отправить</Button>
                        <Button variant="primary" type="button" size="lg" onClick={generateAiResponse} disabled={history.length === 0 || history[history.length - 1].role !== "user"}>Генерировать</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal >
    )
}

